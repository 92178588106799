import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'

export default (() => {
  class LNAVBackdropController {
    constructor(parentNode, selector = '.navrail_subitem') {
      this.elems = [...document?.querySelectorAll(selector)]
      this.enableBackDrop = false
      if (!this.elems.length) {
        return
      }

      const lnavElem = document?.getElementById('m-ahlb-navrail-id')
      if (!lnavElem) {
        return
      }

      const {
        dataset: { defaultItem }
      } = lnavElem
      this.defaultItem = defaultItem
      this.defaultLnavNavrailElems = document?.querySelectorAll('#left-wrap_id ul.navrail')
      this.lnavNavrailElems = document?.querySelectorAll('#m-ahlb-navrail-id ul.navrail')
      this.mwebLnavNavrailElems = document?.querySelectorAll('#lnav-mweb-flyout ul.navrail')
      this.navrailContainerElem = document.querySelector('.m-ahlb')
      this.defaultLNavContainerElem = document.getElementById('left-wrap_id')
      this.navElem = document.querySelector('.m-ahlb .navrail-container')
      this.defaultLNavElem = document.querySelector('#left-wrap_id .navrail-container')

      if (
        !this.defaultLnavNavrailElems.length ||
        !this.lnavNavrailElems.length ||
        !this.mwebLnavNavrailElems.length ||
        !this.navrailContainerElem ||
        !this.defaultLNavContainerElem ||
        !this.navElem ||
        !this.defaultLNavElem
      ) {
        return
      }

      this.transitionTime = 100
      this.focusableItems = [...this.lnavNavrailElems]
      this.defaultFocusableItems = [...this.defaultLnavNavrailElems]
      this.mwebLnavFocusableItems = [...this.mwebLnavNavrailElems]

      this.updateLNavUI({
        updateMenuItem: this.defaultItem,
        forceToUpdate: true
      })

      this.btns = [...this.elems]
      this._init()
    }

    updateLNavElems(elems, updateMenuItem) {
      elems.forEach(elem => {
        elem?.classList?.add('hide')
        if (elem?.dataset?.customLabel === updateMenuItem) {
          elem.classList?.remove('hide')
        }
      })
    }

    updateLNavUI(config) {
      const { updateMenuItem = '', forceToUpdate = false, forceToUpdateForMWeb = false } = config
      this.updateLNavElems(this.lnavNavrailElems, updateMenuItem)

      if (forceToUpdate) {
        this.updateLNavElems(this.defaultLnavNavrailElems, updateMenuItem)
      }

      if (forceToUpdate || forceToUpdateForMWeb) {
        this.updateLNavElems(this.mwebLnavNavrailElems, updateMenuItem)
      }
    }

    _init() {
      this._addEventListeners()
    }

    _addEventListeners() {
      this.btns?.forEach(btn => btn.addEventListener('click', this._handleClick.bind(this)))
      const backBtn = document.querySelector('.m-ahlb-right')
      backBtn?.addEventListener('click', evt => {
        this.rapidBeacon(evt?.currentTarget)
        this._handleBackToDefaulState()
      })

      document.addEventListener('click', evt => {
        if (
          this.enableBackDrop &&
          !(
            evt.target.closest('.navrail') ||
            evt.target.closest('.navrail_subitem') ||
            evt.target.closest('.m-ahlb-right')
          )
        ) {
          this._handleBackToDefaulState()
        }
      })

      document.addEventListener('keyup', evt => {
        if (this.enableBackDrop && evt?.keyCode === 27) {
          this._handleBackToDefaulState()
        }
      })
    }

    async _handleBackToDefaulState() {
      this.enableBackDrop = false
      this._handleLnavClick({
        defaultState: true
      })
      this._handleLnavClickForMWeb({
        defaultState: true
      })
    }

    async _handleLnavClick({ defaultState = false, toLevel, elementValue }) {
      if (this.navrailContainerElem && this.defaultLNavContainerElem) {
        this.navElem?.classList.remove('start_animated')
        this.defaultLNavElem?.classList.remove('start_animated')
        this.navrailContainerElem?.classList.remove('fadeout')

        if (elementValue === this.defaultItem || defaultState) {
          this.enableBackDrop = false
          this.navrailContainerElem?.classList.remove('hide')
          this.navElem?.classList.add('start_animated')
          setTimeout(() => {
            this.updateLNavUI({ updateMenuItem: this.defaultItem })
            this.defaultLNavElem?.classList.remove('start_animated')
            this.navElem?.classList.remove('start_animated')
            this.defaultLNavContainerElem?.classList.remove('hide')
            this.navrailContainerElem?.classList.add('fadeout')
            this.navrailContainerElem?.classList.add('hide')
            this._focusOnFirstElem(this.defaultFocusableItems)
          }, this.transitionTime)
        } else {
          this.enableBackDrop = true
          this.defaultLNavElem?.classList.add('start_animated')
          setTimeout(() => {
            if (Number(toLevel) <= 1) {
              this.updateLNavUI({ updateMenuItem: 'default_1_2' })
            } else {
              this.updateLNavUI({ updateMenuItem: elementValue })
            }
            this.navElem?.classList.remove('start_animated')
            this.defaultLNavElem?.classList.remove('start_animated')
            this.navrailContainerElem?.classList.remove('hide')
            this.defaultLNavContainerElem?.classList.add('hide')
            this._focusOnFirstElem(this.focusableItems)
          }, this.transitionTime)
        }
      }
    }

    _handleLnavClickForMWeb({ defaultState = false, toLevel, elementValue }) {
      if (elementValue === this.defaultItem || defaultState) {
        this.enableBackDrop = false
        this.updateLNavUI({ updateMenuItem: this.defaultItem, forceToUpdateForMWeb: true })
      } else if (Number(toLevel) <= 1) {
        this.enableBackDrop = true
        this.updateLNavUI({
          updateMenuItem: 'default_1_2',
          forceToUpdateForMWeb: true
        })
      } else {
        this.enableBackDrop = true
        this.updateLNavUI({
          updateMenuItem: elementValue,
          forceToUpdateForMWeb: true
        })
      }
      this._focusOnFirstElem(this.mwebLnavFocusableItems)
      const flyoutContainerElem = document.querySelector('.m-flyout__container--left-fixed')
      flyoutContainerElem.scroll(0, 0)
    }

    _handleClick(e) {
      const menuItemElm = e.currentTarget
      const { dataset } = menuItemElm
      const { lnavKey, parentLabel } = dataset
      let { fromLevel, toLevel } = dataset
      fromLevel = Number(fromLevel)
      toLevel = Number(toLevel)
      let elementValue = 'default_1_2'
      if (fromLevel < toLevel) {
        fromLevel += 1
        toLevel += 1

        const elementKey = menuItemElm.querySelector('.text-label').innerText
        if (elementKey) {
          elementValue = `${elementKey
            ?.toLowerCase()
            ?.split(' ')
            ?.join('_')}_${fromLevel}_${toLevel}`
        }
      } else if (fromLevel > toLevel) {
        if (Number(toLevel) <= 1) {
          elementValue = 'default_1_2'
        } else {
          const temp = fromLevel
          fromLevel = toLevel
          toLevel = temp
          elementValue = parentLabel
        }
      }

      if (lnavKey !== 'mweb') {
        this._handleLnavClick({ toLevel, elementValue })
      } else if (lnavKey === 'mweb') {
        this._handleLnavClickForMWeb({ toLevel, elementValue })
      }
    }

    _focusOnFirstElem(elems) {
      if (elems.length) {
        for (let index = 0; index < elems.length; index++) {
          if (!elems[index]?.classList?.contains('hide')) {
            const elem = elems[index]?.querySelectorAll('li a,li button')
            if (elem.length) {
              elem[0].focus()
            }
            break
          }
        }
      }
    }

    rapidBeacon(target, overridesYlk = {}) {
      const { ylk = {} } = target?.dataset
      let ylkObj = ylkStringToObj(ylk)
      ylkObj = { ...ylkObj, ...overridesYlk }
      rapidReady(rapid => {
        rapid.beaconClick(ylkObj.sec, ylkObj.slk, ylkObj.itc, ylkObj)
      })
    }
  }

  return LNAVBackdropController
})()
