const { getObjectValue } = require('../../../../../lib/utils/obj')

export default (() => {
  class Jws {
    get wssid() {
      return getObjectValue(window, ['AOL', 'user', 'wssid'], '')
    }

    set wssid(wssid) {
      window.AOL = window.AOL || {}
      window.AOL.user = window.AOL.user || {}
      window.AOL.user.wssid = wssid || ''
    }

    /**
     * Fetch Preview
     * @method fetchPreview
     * @method {Obect} requestDetails - The details of the request obj ie { data = {}, headers = {}, params = {} }
     * @method {Object} Config - config for request
     * @return {Array} Mail Preview
     */
    async fetchPreview({ params = {}, headers = {} } = {}, { maxMessages = 4, locale } = {}) {
      const retries = 1
      const httpOptions = {
        data: {
          responseType: 'json',
          requests: [
            {
              id: 'GetMailboxId',
              uri: '/ws/v3/mailboxes/',
              method: 'GET',
              filters: {
                select: {
                  mailboxId: '$..mailboxes[?(@.isPrimary==true)].id'
                }
              },
              suppressResponse: true,
              requests: [
                {
                  id: 'ListMessages',
                  uri:
                    '/ws/v3/mailboxes/@.id==$(mailboxId)/messages/@.select==q?q=count%3A4+offset%3A0+folderType%3AINBOX+-sort%3Adate',
                  method: 'GET'
                }
              ]
            }
          ]
        },
        headers,
        params: {
          ...params
        }
      }
      return this.fetch(httpOptions, retries, data =>
        this._normalizePreviewResponse(data, { maxMessages, locale })
      )
    }

    /**
     * Fetch Count
     * @method fetchCount
     * @method {Obect} requestDetails - The details of the request obj ie { data = {}, headers = {}, params = {} }
     * @return {Int} Count
     */
    async fetchCount({ params = {}, headers = {} } = {}) {
      const retries = 1
      const httpOptions = {
        data: {
          responseType: 'json',
          requests: [
            {
              id: 'GetMailboxId',
              uri: '/ws/v3/mailboxes/',
              method: 'GET',
              filters: {
                select: {
                  mailboxId: '$..mailboxes[?(@.isPrimary==true)].id'
                }
              },
              requests: [
                {
                  id: 'GetAccounts',
                  uri: '/ws/v3/mailboxes/@.id==$(mailboxId)/accounts',
                  method: 'GET',
                  filters: {
                    select: {
                      accountId: '$..accounts[?(@.isPrimary==true)].id'
                    }
                  },
                  requests: [
                    {
                      id: 'GetFolders',
                      uri: '/ws/v3/mailboxes/@.id==$(mailboxId)/folders',
                      method: 'GET',
                      filters: {
                        select: {
                          folderId:
                            "$..folders[?(@.acctId=='$(accountId)' && 'INBOX' in @.types)].id"
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        headers,
        params: {
          ...params
        }
      }
      return this.fetch(httpOptions, retries, data => this._normalizerCountResponse(data))
    }

    /**
     * Fetch JWS
     * @method fetch
     * @method {Obect} requestDetails - The details of the request obj ie { data = {}, headers = {}, params = {} }
     * @method {int} pendingRetries - The number of retries left
     * @method {Function} responseNormalizer - Function to normalise the resonse
     * @return {Object|null} Returns date from the JWS api
     */
    async fetch(requestDetails, pendingRetries, responseNormalizer) {
      const { data = {}, headers = {}, params = {} } = requestDetails
      // (https://docs.google.com/document/d/e/2PACX-1vRS89WTZ5BJyAOmms37xVmrQGvhPdz_lGGqKFUbzCXZ04oQezaZj8Ncx_ITdOWSONXWpFq84NCxuLHK/pub#h.ldgmgu6ifar7)
      const authFailErrorCode = 'EC-4008' // Authorization Fail Error Code
      const invalidWssidErrorCode = 'EC-4003' // Expired WSSID Error Code
      const responseResultPath = ['result', 'responses']
      const responseErrorCodePath = ['error', 'code']
      const ymreqid = this._getYmreqid()

      const paramObj = { appid: 'aolportal', ...params }

      if (this.wssid) {
        paramObj.wssid = this.wssid
      }

      const urlParams = Object.keys(paramObj).map(
        paramKey => `${encodeURIComponent(paramKey)}=${encodeURIComponent(paramObj[paramKey])}`
      )

      const url = `/ws/v3/batch?${urlParams.join('&')}`

      try {
        const responseData = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'x-oath-ymreqid': ymreqid,
            ...headers
          },
          timeout: 2000
        })

        const responseJson = await responseData.json()

        if (!responseData.ok) {
          const errorCode = getObjectValue(responseJson, responseErrorCodePath)
          // Handle invalid WSSID Error Response
          if (errorCode === invalidWssidErrorCode) {
            return this._handleWssidApiError(
              responseJson,
              requestDetails,
              pendingRetries,
              responseNormalizer
            )
          }
          // Handle authorization failure / Expired Session
          if (errorCode === authFailErrorCode) {
            throw new Error('Session has expired')
            // Handle any other type of API error response
          } else {
            throw new Error('Mail Api Responded with an Error')
          }
        } else {
          const apiResponse = getObjectValue(responseJson, responseResultPath)
          return responseNormalizer(apiResponse)
        }
      } catch (error) {
        console.error(error)
        throw error
      }
    }

    // PRIVATE METHODS

    /**
     * Normalize Preview response
     * @method _normalizePreviewResponse
     * @method {Obect} data - data to normalize
     * @param {Object} config - configs for function
     * @return {int} Returns normalized previews
     */
    _normalizePreviewResponse(data, { maxMessages = 4, locale } = {}) {
      if (!data) {
        return []
      }
      // const locale = getObjectValue(req, ['context', 'lang'], 'en-US').toLowerCase();
      const rawMessages = getObjectValue(data, [0, 'response', 'result', 'messages'], [])
      const parsedMessages = rawMessages
        .map(m => this._parseRawMessage(m, locale))
        .filter((message, index) => (index < maxMessages ? message : false))
      return parsedMessages
    }

    /**
     * Normalize Count response
     * @method _normalizerCountResponse
     * @method {Obect} data - data to normalize
     * @return {int} Returns count
     */
    _normalizerCountResponse(data) {
      const requestObjId = 'GetFolders'
      const folderID = 'Inbox'
      const folderPath = ['response', 'result', 'folders']

      const folders = data.find(reqObj => reqObj && reqObj.id === requestObjId)
      if (!folders) {
        return 0
      }

      const inbox = getObjectValue(folders, folderPath, []).find(
        folder => folder && folder.name === folderID
      )
      const unreadCount = parseInt(getObjectValue(inbox, ['unread'], 0), 10)
      return unreadCount
    }

    /**
     * Handle wssid response error
     * @method _handleWssidApiError
     * @param {Object} errorResponse - error data
     * @method {Obect} requestDetails - The details of the request obj ie { data = {}, headers = {}, params = {} }
     * @method {int} pendingRetries - The number of retries left
     * @method {Function} responseNormalizer - Function to normalise the resonse
     * @return {Object|null} Returns another fetch call or throw and error
     */
    _handleWssidApiError(errorResponse, requestDetails, pendingRetries, responseNormalizer) {
      const responseWssidPath = ['error', 'details', 'wssid']
      const responseWssidValue = getObjectValue(errorResponse, responseWssidPath)
      const { params: { wssid } = {} } = requestDetails
      // Exit early if WSSID is not present
      if (!responseWssidValue) {
        throw new Error('valid WSSID missing from API Response')

        // Validate the API provided WSSID is not the same as the one we used for the request
      } else if (responseWssidValue === wssid) {
        throw new Error('API provided same invalid WSSID')

        // Retry the request using the new WSSID provided by the API
      } else {
        // Retry the request if there are retries available
        // Use the new WSSID provided by the API
        if (typeof pendingRetries !== 'undefined' && pendingRetries > 0) {
          pendingRetries -= 1
          this.wssid = responseWssidValue
          return this.fetch(requestDetails, pendingRetries, responseNormalizer)
        }
        throw new Error('Max retries with invalid WSSID reached')
      }
    }

    /**
     * Parse Raw Message
     * @method _parseRawMessage
     * @param {Object} rawMessage - messages from api respose
     * @method {String} locale - The locale of the request, eg: en-US, en-GB
     * @return {Object|null} The parsed message object
     */
    _parseRawMessage(rawMessage, locale = 'en-US') {
      const { id, snippet, headers, flags } = rawMessage
      if (headers && headers.date && getObjectValue(headers, ['from', '0'])) {
        return {
          link: `https://mail.aol.com/${locale.toLowerCase()}/DisplayMessage.aspx?folder=Inbox&uid=${id}&icid=apimail_flac_wl`,
          sender: `"${getObjectValue(headers, ['from', '0', 'name'])}" <${getObjectValue(
            headers,
            ['from', '0', 'email'],
            ''
          )}>`,
          subject: headers.subject || '',
          digest: snippet,
          timestamp: Number(headers.date) * 1000, // headers.date is in seconds
          isRead: flags.read
        }
      }
      return null
    }

    /**
     * Get Ymreqid
     * @method getYmreqid
     * @return {String} Ymreqid
     */
    _getYmreqid() {
      let d = new Date().getTime()
      const uuid = 'xxxxxxxx-xxxx-xxxx-09xx-xxxxxxxxxx00'.replace(new RegExp('x', 'g'), () => {
        /* eslint no-bitwise: ["error", { "int32Hint": true }] */
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return r.toString(16)
      })
      return uuid
    }
  }

  return Jws
})()
