const el = require('../../../lib/utils/jsx_component');
module.exports = () => {
  return el("div", {
    className: "m-mail-preview__content--inner"
  }, el("div", {
    className: "m-mail-preview__placeholder"
  }, el("img", {
    src: "https://s.aolcdn.com/aoldotcom-releases/site/loading-1.svg",
    alt: "mail preview"
  })));
};