const SELF_CLOSING_TAGS = ['img', 'link', 'meta', 'br', 'hr', 'input', 'iframe']
const SVG_TAGS = [
  'svg',
  'path',
  'circle',
  'rect',
  'polygon',
  'defs',
  'linearGradient',
  'stop',
  'ellipse',
  'text'
]

const arrayIncludesItem = (arr, item) => {
  let includes = false
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] === item) {
      includes = true
      break
    }
  }
  return includes
}
const isElemValid = elem => elem !== null
const appendOrCreate = element => {
  if (element instanceof Node) {
    return element
  }
  if (typeof element === 'string' || typeof element === 'number') {
    return document.createTextNode(element)
  }
  return null
}

const renderClient = (tag, attrs, ...children) => {
  if (typeof tag === 'function') {
    return tag()
  }
  if (typeof tag !== 'string') {
    return null
  }
  const fragments = document.createDocumentFragment()
  const element = arrayIncludesItem(SVG_TAGS, tag)
    ? document.createElementNS('http://www.w3.org/2000/svg', tag)
    : document.createElement(tag)

  children.forEach(child => {
    if (Array.isArray(child)) {
      child.forEach(c => isElemValid(c) && fragments.appendChild(appendOrCreate(c)))
    } else {
      isElemValid(child) && fragments.appendChild(appendOrCreate(child))
    }
  })
  element.appendChild(fragments)

  const attrsObj = attrs || {}
  Object.keys(attrsObj).forEach(key => {
    const k = key === 'className' ? 'class' : key
    element.setAttribute(k, attrsObj[key])
  })

  return element
}

const renderServerChildren = children => {
  let markup = ''
  children.forEach(child => {
    if (Array.isArray(child)) {
      child.forEach(c => {
        isElemValid(c) && (markup += c)
      })
    } else {
      isElemValid(child) && (markup += child)
    }
  })
  return markup
}

const renderServer = (tag, attrs = {}, ...children) => {
  const attrsObj = attrs || {}
  const attributes = Object.keys(attrsObj)
    .map(key => {
      const k = key === 'className' ? 'class' : key
      return `${k}="${attrsObj[key]}"`
    })
    .join(' ')

  return arrayIncludesItem(SELF_CLOSING_TAGS, tag)
    ? `<${tag} ${attributes} />`
    : `<${tag} ${attributes}>${renderServerChildren(children)}</${tag}>`
}

const el = (tag, attrs, ...children) => {
  if (typeof window !== 'undefined') {
    return renderClient(tag, attrs, ...children)
  }
  return renderServer(tag, attrs, ...children)
}

module.exports = el
