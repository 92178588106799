const { errorLogger, Constants } = require('../ads/utils')

const logError = (moduleName, e, level = Constants.ERROR_LEVEL.ERROR) => {
  const error = typeof e === 'string' ? new Error(e) : e
  const logger = errorLogger()
  logger(`[${moduleName} ${level}] ${error?.message || 'Generic Error'}`, error)
}

module.exports = {
  logError
}
