export const Constants = {
  ERROR_LEVEL: {
    ERROR: 'ERROR',
    WARN: 'WARN'
  }
}

export const fetchJSONP = (url, query, cbParamName = 'cb') =>
  new Promise((resolve, reject) => {
    const timestamp = Date.now()
    const randomNum = Math.ceil(Math.random() * 1000)
    const script = document.createElement('script')
    const name = `_jsonp_${timestamp}_${randomNum}`
    const queryArr = [`${cbParamName}=${name}`]
    Object.keys(query).forEach(key => {
      queryArr.push(`${key}=${query[key]}`)
    })

    url += `?${queryArr.join('&')}`

    script.src = url
    script.onerror = () => {
      reject(new Error(`Request Failed: ${url}`))
    }
    window[name] = json => {
      resolve(new Response(JSON.stringify(json)))
      script.parentNode.removeChild(script)
      delete window[name]
    }

    document.body.appendChild(script)
  })

export const errorLogger = () => {
  let logger = window.newrelic?.noticeError || console.error
  if (process.env.NODE_ENV === 'development') {
    logger = console.error
  }
  return logger
}

export default {
  Constants,
  fetchJSONP,
  errorLogger
}
