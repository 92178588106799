import Jws from './services/jws'
import placeholder from '../../renderers/mail_placeholder'
import previewRenderer from '../../renderers/mail_preview'
import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'
import { dispatchCustomEvent } from '../../../../lib/utils/events'
import IntervalTimer from './interval_timer'

const CLASS_PREVIEW_OPEN = 'header-mail-preview-open'
const CLASS_PREVIEW_HIDDEN = 'm-mail-preview--hidden'
const CLASS_MAIL_BADGES = 'mail-badges'
const DEFAULT_LOCALE = 'en-us'
const INTERVAL_MAIL_COUNT_REFRESH = 120000 // 2mins
const TIMER_MAIL_PREVIEW_FRESHNESS = 120000 // 2mins
const WIDTH_DESKTOP = 900

export default (() => {
  class Mail {
    constructor({ mailCountSelector, mailPreviewSelector, mailPreviewContentSelector }) {
      this.mailCountElems = [...document.querySelectorAll(mailCountSelector)]
      this.mailPreviewElem = document.querySelector(mailPreviewSelector)
      this.ylk = {}
      if (this.mailPreviewElem) {
        this.locale = this.mailPreviewElem.dataset.locale || DEFAULT_LOCALE
        this.mailPreviewContent = this.mailPreviewElem.querySelector(mailPreviewContentSelector)
        this.ylk = ylkStringToObj(this.mailPreviewElem.dataset.ylk || '')
      }
      this.staleContent = true
      this.service = new Jws()
      this.init()
    }

    // GETTERS
    get unreadCount() {
      return this.mailCount || 0
    }

    get mailPreview() {
      return this.mailPreviewItems || []
    }

    // SETTERS
    set unreadCount(count) {
      document.body.classList[count > 0 ? 'add' : 'remove'](CLASS_MAIL_BADGES)
      this.mailCount = count
      const displayCount = count > 999 ? `${(count / 1000).toFixed(1)}K` : count
      this.mailCountElems.forEach(elem => {
        elem.textContent = displayCount
      })
    }

    set mailPreview(items) {
      this.mailPreviewItems = items
      this._renderMailPreview()
    }

    // PUBLIC METHODS
    init() {
      // Mail Count
      if (this.mailCountElems.length) {
        this.mailCountInterval = new IntervalTimer(() => {
          this._fetchUnreadCount().then(count => {
            this.unreadCount = count
          })
        }, INTERVAL_MAIL_COUNT_REFRESH)
        this.mailCountInterval.exec()
      }

      // Mail Preview
      if (this.mailPreviewElem && this.mailPreviewContent) {
        this.addMailPreviewEventListeners()
      }

      window.addEventListener('focus', () => {
        this.mailCountInterval && this.mailCountInterval.exec()
        this.staleContent = true
      })
    }

    addMailPreviewEventListeners() {
      this.mailPreviewElem.addEventListener('mouseenter', e => {
        this.rapidEvent({ itc: 2 })
        this.handleMouseEnter(e)
      })
      this.mailPreviewElem.addEventListener('mouseleave', this.handleMouseLeave.bind(this))
      this.mailPreviewElem.addEventListener('focusin', this.handleMouseEnter.bind(this))
      this.mailPreviewElem.addEventListener('focusout', e => {
        if (!this.mailPreviewElem.contains(e.relatedTarget)) {
          this._hidePreview()
        }
      })
    }

    // EVENT HANDLERS
    handleMouseEnter() {
      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      if (windowWidth < WIDTH_DESKTOP) {
        return
      }

      this._showPreview()

      if (this.mailPreview.length === 0) {
        this.mailPreviewContent.innerHTML = ''
        this.mailPreviewContent.appendChild(placeholder())
      }

      /**
       * To stop the client making a req to the mail api on every mouse enter
       * the content is assumed to be fresh for the duration of the this.staleContentTimer timeout
       * Basically meaning it will only request new previews every ${TIMER_MAIL_PREVIEW_FRESHNESS} ms
       */
      if (this.staleContent || this.mailPreview.length === 0) {
        this._fetchMailPreview().then(items => {
          if (items.length === 0) {
            this._hidePreview()
          }
          this.mailPreview = items
          this.staleContent = false
          this.staleContentTimer = setTimeout(() => {
            this.staleContent = true
          }, TIMER_MAIL_PREVIEW_FRESHNESS)
        })
      }
    }

    handleMouseLeave() {
      this._hidePreview()
    }

    // PRIVATE METHODS
    async _fetchMailPreview() {
      let items
      try {
        items = await this.service.fetchPreview({}, { locale: this.locale })
      } catch (e) {
        items = []
      }
      return items || []
    }

    async _fetchUnreadCount() {
      let count
      try {
        count = await this.service.fetchCount()
      } catch (e) {
        count = 0
      }
      return count || 0
    }

    _renderMailPreview() {
      this.mailPreviewContent.innerHTML = ''
      this.mailPreviewContent.appendChild(previewRenderer(this.mailPreview, this.locale))
    }

    _showPreview() {
      document.body.classList.add(CLASS_PREVIEW_OPEN)
      this.mailPreviewElem.classList.remove(CLASS_PREVIEW_HIDDEN)
      dispatchCustomEvent('flyout:closeAll')
    }

    _hidePreview() {
      document.body.classList.remove(CLASS_PREVIEW_OPEN)
      this.mailPreviewElem.classList.add(CLASS_PREVIEW_HIDDEN)
    }

    rapidEvent(overrides = {}) {
      const ylk = { ...this.ylk, ...overrides }
      rapidReady(rapid => {
        rapid.beaconClick(ylk.sec, ylk.slk, ylk.pos, ylk)
      })
    }
  }

  return Mail
})()
