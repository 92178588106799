const { paramCase } = require('param-case')

/**
 * Retrieves the subproperty of an object at the provided path, or a fallback default
 *
 * getObjectValue
 *
 * @param {Object} obj The object from which to extract the property value.
 * @param {Array<string|number>} path Array of strings or ints representing path to prop
 * @param {any} defaultValue value to return if prop is undefined
 * @return {any} The value stored in the path, or defaultValue if undefined
 */
const getObjectValue = (obj, path, defaultValue = undefined) => {
  path.some(key => {
    const useFallback = !obj || typeof obj[key] === 'undefined'
    obj = useFallback ? defaultValue : obj[key]
    return useFallback
  })
  return path.length > 0 ? obj : defaultValue
}

/**
 * Removed undefined properties from an object
 * @param {Object} obj - Object
 * @return {Object} Object without any undefined properties
 */
const removeUndefinedProps = obj =>
  Object.entries(obj).reduce((acc, [k, v]) => {
    if (typeof v === 'undefined') return acc
    acc[k] = v
    return acc
  }, {})

/**
 * This function converts an objects key names to their associated wafer attribute names
 * EXAMPLE:
 * Input:
 * {
 *   viewability: 35
 *   adViewContinuous: 1
 *   someJsonConfig: { test: 'test'}
 * }
 *
 * Output:
 *  {
 *   data-wf-viewability: 35
 *   data-wf-ad--view-continuous: 1
 *   data-wf-some-json-config: '{"test": "test"}'
 * }
 *
 * @param {Object} obj - Object to be converted to object of wafer attributes
 * @return {Object} Object of wafer attributes
 */
const waferizeObject = obj =>
  Object.entries(obj).reduce((attrs, [key, value]) => {
    const serializedValue = typeof value === 'object' ? JSON.stringify(value) : value
    attrs[`data-wf-${paramCase(key)}`] = serializedValue
    return attrs
  }, {})

module.exports = {
  getObjectValue,
  removeUndefinedProps,
  waferizeObject
}
