/**
 * Create a custom event
 * @param {string} eventName - name of event
 * @param {Object} data - object to send when event is fired
 */
const dispatchCustomEvent = (eventName, data) => {
  const customEvent = document.createEvent('CustomEvent')
  customEvent.initCustomEvent(eventName, false, false, data)
  document.dispatchEvent(customEvent)
}

module.exports = { dispatchCustomEvent }
