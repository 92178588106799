const formatDistance = require('date-fns/formatDistance');
const el = require('../../../lib/utils/jsx_component');
module.exports = (mail = [], locale = 'en-us') => {
  let tld;
  switch (locale.toLowerCase()) {
    case 'en-gb':
      tld = 'co.uk';
      break;
    case 'en-ca':
      tld = 'ca';
      break;
    case 'de-de':
      tld = 'de';
      break;
    case 'ja-pa':
      tld = 'jp';
      break;
    case 'en-us':
    default:
      tld = 'com';
      break;
  }
  const showMail = mail.length > 0;
  if (!showMail) {
    return el("div", null);
  }
  const list = el("ul", {
    className: "m-mail-preview__list"
  }, mail.map(({
    link,
    sender,
    subject,
    digest,
    timestamp,
    isRead
  }) => el("li", {
    className: `m-mail-preview__item ${!isRead ? 'm-mail-preview__item--unread' : ''}`
  }, el("a", {
    className: "m-mail-preview__item--link",
    href: link
  }, el("div", {
    className: "m-mail-preview__item--meta"
  }, el("span", {
    className: "m-mail-preview__item--sender"
  }, sender), el("abbr", {
    className: "m-mail-preview__item--time"
  }, formatDistance(new Date(timestamp), new Date(), {
    addSuffix: true
  }))), el("h4", {
    className: "m-mail-preview__item--subject"
  }, subject), el("p", {
    className: "m-mail-preview__item--digest"
  }, digest)))));
  const links = el("div", {
    className: "m-mail-preview__footer"
  }, el("a", {
    className: "m-mail-preview__footer--all",
    href: `http://mail.aol.${tld}/?icid=aol.${tld}-nav`
  }, "View All Mail"), el("a", {
    className: "m-mail-preview__footer--compose",
    href: `http://mail.aol.com/${locale}/compose-message.aspx`
  }, "Compose"));
  return el("div", {
    className: 'm-mail-preview__content--inner'
  }, [list, links]);
};