import { rapidReady } from '../../../../lib/utils/rapid'

const CLASS_HIDDEN = 'm-banner--hidden'
const CLASS_BTN_CLOSE = 'm-banner__btn--close'
const CLASS_BTN_PROMPT = 'm-banner__btn--prompt'
const CLASS_BTN_LINK = 'm-banner__btn--link'
const LS_KEY = 'aolhb'
const KEY_LAST_SEEN = 'lastSeen'
const KEY_MANUALLY_CLOSED_COUNT = 'manuallyClosedCount'
const KEY_LAST_CLOSED = 'lastClosed'
const KEY_LINK_CLICKED = 'linkClicked'
const TYPE_PRIVACY = 'privacy'
const TYPE_MTMHP = 'mtmhp'
const TYPE_CARE = 'care'
const TYPE_CHROME_APP = 'chromeAppBanner'
const TYPE_AD = 'bannerAd'
const TYPE_CONSENTJS = 'consentjs'
const MAX_BANNERS = 2
const EVENT_BEFORE_INSTALL_PROMPT = 'beforeinstallprompt'
const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24

export default (() => {
  class BannerController {
    constructor({ parentNode, selector = '.m-banner' } = {}) {
      this.header = parentNode
      this.banners = [...(parentNode || document).querySelectorAll(selector)]
      if (!this.banners.length) {
        return
      }
      this.bannersShown = this.banners.filter(
        banner => !banner.classList.contains(CLASS_HIDDEN)
      ).length
      this.chromeAppBannerPrompt = null
      this.presistantState = this._getLocalStorage()
      this.queue = []
      this.banners
        .sort((a, b) => (a.dataset.priority > b.dataset.priority ? 1 : -1))
        .forEach(banner => this.init(banner))

      this.showNextInQueue()
    }

    showNextInQueue() {
      if (this.queue.length) {
        this.show(this.queue.shift())
      }
    }

    init(banner) {
      banner._key = banner.dataset.key

      if (this._shouldShowOnLoad(banner)) {
        this.queue.push(banner)
      }
      const closeBtn = banner.querySelector(`.${CLASS_BTN_CLOSE}`)
      closeBtn?.addEventListener('click', () => {
        this.close(banner)
        this._setState(banner, KEY_MANUALLY_CLOSED_COUNT, this._getManualClosedCount(banner) + 1)
        this._setState(banner, KEY_LAST_CLOSED)
      })

      const linkElm = banner.querySelector(`.${CLASS_BTN_LINK}`)
      linkElm?.addEventListener('click', () => {
        this._setState(banner, KEY_LINK_CLICKED, this._getLinkClickCount(banner) + 1)
      })
    }

    close(banner) {
      this.bannersShown -= 1
      banner.classList.add(CLASS_HIDDEN)
      this.unsetSticky()
    }

    show(banner) {
      this.activeBanner = banner
      this.bannersShown += 1
      banner.classList.remove(CLASS_HIDDEN)
      this._setState(banner, KEY_LAST_SEEN)

      // Refresh Rapid module after open transition is over
      setTimeout(() => {
        rapidReady(rapid => {
          if (rapid.isModuleTracked(banner.id)) {
            rapid.refreshModule(banner.id)
          }
        })
      }, 400)
    }

    syncLocalStorage() {
      const stringified = JSON.stringify(this.presistantState)
      const base64Encoded = window.btoa(stringified)
      window.localStorage.setItem(LS_KEY, base64Encoded)
    }

    _getLocalStorage() {
      const lsString = window.localStorage.getItem(LS_KEY)
      let lsObj = {}
      if (!lsString) {
        return lsObj
      }

      try {
        const json = window.atob(lsString)
        lsObj = JSON.parse(json)
      } catch (e) {
        // failed to read LS, clean up the corrupt memory
        window.localStorage.removeItem(LS_KEY)
      }

      return lsObj
    }

    _shouldShowOnLoad(banner) {
      switch (banner._key) {
        case TYPE_PRIVACY:
          return !this._hasBeenSeenBefore(banner) && !this._hasBeenManuallyClosed(banner)
        case TYPE_CHROME_APP:
          this.addBeforeInstallPromptListener(banner)
          return false
        case TYPE_MTMHP:
          return this._shouldShowMTHPM(banner)
        case TYPE_CARE:
          return true
        case TYPE_AD:
          this.addAdLoadListener(banner)
          return false
        case TYPE_CONSENTJS:
          this.addConsentJsBannerListeners(banner)
          return false
        default:
          return false
      }
    }

    addConsentJsBannerListeners(banner) {
      if (banner) {
        banner.addEventListener('cjsBannerDisplay', () => {
          this.show(banner)
        })
        banner.addEventListener('cjsBannerClose', () => {
          this.close(banner)
        })
        banner.addEventListener('cjsBannerResize', e => {
          banner.style.height = `${e.detail.height}px`
        })
      }
    }

    addBeforeInstallPromptListener(banner) {
      window.addEventListener(EVENT_BEFORE_INSTALL_PROMPT, e => {
        e.preventDefault()
        this.chromeAppBannerPrompt = e
        if (this.bannersShown < MAX_BANNERS) {
          this.show(banner)
        } else {
          this.queue.push(banner)
        }
      })

      const promptBtn = banner.querySelector(`.${CLASS_BTN_PROMPT}`)
      promptBtn?.addEventListener('click', () => {
        this.close(banner)

        this.chromeAppBannerPrompt.prompt()

        this.chromeAppBannerPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
            // console.log('User accepted the A2HS prompt')
          } else {
            // console.log('User dismissed the A2HS prompt')
          }
          this.chromeAppBannerPrompt = null
        })
      })
    }

    addAdLoadListener(banner) {
      this.queue.push(banner)

      document.addEventListener('header:adLoad', ({ detail }) => {
        const { isEmpty, size = [0, 0] } = detail
        const [, height] = size || [0, 0]
        if (isEmpty || height === 0) {
          this.close(banner)
          banner.parentNode.removeChild(banner)
          this.showNextInQueue()
        } else if (this.activeBanner === banner) {
          this.setSticky()
        }
      })
    }

    _shouldShowMTHPM(banner) {
      if (banner._key !== TYPE_MTMHP) return false
      const hasManuallyClosed = this._hasBeenManuallyClosed(banner)
      const hasLinkBeenClicked = this._hasLinkBeenClicked(banner)

      if (hasLinkBeenClicked) return false
      if (hasManuallyClosed) {
        const closeCount = this._getManualClosedCount(banner)
        const daysSinceLastClosed = Math.trunc(
          (Date.now() - this._getLastCloseDate(banner)) / DAY_IN_MILLISECONDS
        )

        // Show/Hide timing logic defined in this ticket: https://jira.ouryahoo.com/browse/AOLHP-117
        switch (closeCount) {
          case 1: // If a users has closed the banner once, we do not show again for 90 days
            return daysSinceLastClosed > 90
          case 2: // If a users has closed the banner once, we do not show again for 180 days
            return daysSinceLastClosed > 180
          case 3: // If they close it a 3rd time, we do not show it again
          default:
            return false
        }
      }

      return true
    }

    _setState(banner, stateKey, value = Date.now()) {
      const bannerState = this.presistantState[banner._key] || {}
      bannerState[stateKey] = value
      this.presistantState[banner._key] = bannerState
      this.syncLocalStorage(this.presistantState)
    }

    _hasBeenSeenBefore(banner) {
      const bannerState = this.presistantState[banner._key]
      return !!bannerState?.[KEY_LAST_SEEN]
    }

    _hasBeenManuallyClosed(banner) {
      const manualCloseCount = this._getManualClosedCount(banner)
      return manualCloseCount > 0
    }

    _getManualClosedCount(banner) {
      const bannerState = this.presistantState[banner._key]
      return bannerState?.[KEY_MANUALLY_CLOSED_COUNT] || 0
    }

    _getLastCloseDate(banner) {
      const bannerState = this.presistantState[banner._key]
      return bannerState?.[KEY_LAST_CLOSED] || 0
    }

    _getLinkClickCount(banner) {
      const bannerState = this.presistantState[banner._key]
      return bannerState?.[KEY_LINK_CLICKED] || 0
    }

    _hasLinkBeenClicked(banner) {
      const count = this._getLinkClickCount(banner)
      return count > 0
    }

    setSticky() {
      this.header?.classList.add('m-AolHeader--sticky')
    }

    unsetSticky() {
      if (!this.bannersShown) {
        this.header?.classList.remove('m-AolHeader--sticky')
      }
    }
  }

  return BannerController
})()
