export default class IntervalTimer {
  constructor(fn = () => {}, interval = 0) {
    this.fn = fn
    this.interval = interval
    this.timer = null
  }

  start() {
    if (this.timer) return
    this.timer = setInterval(this.fn, this.interval)
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  reset() {
    this.stop()
    this.start()
  }

  exec(resetTimer = true) {
    resetTimer && this.stop()
    this.fn()
    resetTimer && this.start()
  }
}
